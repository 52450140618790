<template>
  <div class="homepage-5 the-search hd-white">
    <div id="wrapper">
      <section
        style="
          background-color: #272c32;
          background-position: center center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          background-size: cover;
        "
        id="hero-area"
        class="parallax-searchs home17 overlay res-bimg"
        data-stellar-background-ratio="0.5"
        v-lazyload
        :data-url="require('../assets/images/bighome.png')"
      >
        <div class="hero-main">
          <div class="container">
            <div class="banner-inner-wrap res-p res-display-unset">
              <div
                class="row justify-content-md-center w1001 res-margin-seting"
              >
                <div class="col-12 mb-3 mt-3">
                  <div class="banner-inner justify-content-center">
                    <div class="pl-3 pr-3">
                      <h1 class="title text-center mb-0">
                        The smart property marketplace
                      </h1>
                      <h3
                        class="mt-2 text-center res-styles"
                        style="color: white"
                      >
                        Search properties for sale in the UK
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 res-plr-0 resp-margin-topp">
                  <div class="banner-search-wrap">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade show active res-marginr-20"
                        id="tabs_1"
                      >
                        <div>
                          <div
                            class="rld-main-search"
                            :style="`
                            ${
                              searchOpen
                                ? `border-bottom-left-radius: 0px;
                              border-bottom-right-radius: 0px;`
                                : ''
                            }
                              `"
                          >
                            <div
                              class="row"
                              style="margin-left: -21px; margin-right: -21px"
                            >
                              <div
                                style="padding-left: 20px"
                                class="rld-single-input res-pl-0 res-mrl-15"
                              >
                                <input
                                  class="
                                    ml-2
                                    res_remove_ml
                                    w-124
                                    res-postal-code
                                  "
                                  type="text"
                                  placeholder="Postcode/Town"
                                  @change="
                                    addToQuery('postCode', $event.target.value)
                                  "
                                />
                              </div>
                              <div class="rld-single-select res-mrl-15 w-124">
                                <multiselect
                                  :searchable="false"
                                  select-label=""
                                  deselectLabel=""
                                  selectedLabel=""
                                  v-if="findIfActive('location') == 1"
                                  @input="
                                    addToQuery(
                                      'radius',
                                      _.get($event, 'value', '')
                                    )
                                  "
                                  :placeholder="'Search Radius'"
                                  v-model="radiusOptions"
                                  :options="[
                                    { label: 'Within 1/4 mile', value: '1/4' },
                                    { label: ' Within 1/2 mile', value: '1/2' },
                                    { label: 'Within 3 miles', value: '3' },
                                    {
                                      label: 'Within 5 miles',
                                      value: '5',
                                    },
                                    { label: 'Within 10 miles', value: '10' },
                                    { label: 'Within 15 miles', value: '15' },
                                    { label: 'Within 20 miles', value: '20' },
                                    { label: 'Within 30 miles', value: '30' },
                                    { label: 'Within 40 miles', value: '40' },
                                  ]"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  label="label"
                                  track-by="value"
                                >
                                  <template
                                    slot="selection"
                                    slot-scope="{ values, isOpen }"
                                    ><span
                                      class="multiselect__single"
                                      v-if="values.length &amp;&amp; !isOpen"
                                      >{{ values.length }} option{{
                                        values.length > 1 ? "s" : ""
                                      }}
                                      selected</span
                                    ></template
                                  >
                                  <template #tag
                                    ><p class="d-none"></p>
                                  </template>
                                </multiselect>
                              </div>

                              <div
                                style="padding-right: 25px"
                                class="col res-search-button-new pl-0"
                              >
                                <button
                                  style="
                                    border-color: #548f4d !important;
                                    color: White !important;
                                  "
                                  @click="search"
                                  class="btn btn-yellow"
                                >
                                  Search Now
                                </button>
                              </div>
                              <div
                                :style="`
                                  width: 100%;
                                  padding:0;
                                  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 9px;
                                  
                                  border-top-left-radius: 0px;
                                  border-top-right-radius: 0px;
                                  border-bottom-left-radius: 5px;
                                  border-bottom-right-radius: 5px;
                                  `"
                                class="explore__form-checkbox-list full-filter"
                              >
                                <div
                                  class="inside-advance"
                                  style="padding: 30px 30px 20px 30px"
                                >
                                  <div
                                    class="
                                      col-lg-12 col-md-12 col-sm-12
                                      py-1
                                      pr-30
                                      mr-5
                                      sld
                                    "
                                  >
                                    <div class="main-search-field-2">
                                      <div
                                        v-if="findIfActive('priceRange') == 1"
                                        class="range-slider"
                                      >
                                        <label
                                          >Price Range: £{{
                                            formatter(value[0])
                                          }}
                                          to £{{ formatter(value[1]) }}</label
                                        >
                                        <vue-range-slider
                                          :tooltip="false"
                                          :data="rangeValues"
                                          @slide-end="
                                            addToQuery('price', $event)
                                          "
                                          :tooltip-dir="'bottom'"
                                          :tooltip-style="{
                                            'background-color': '#3e4452',
                                            'border-color': '#3e4452',
                                          }"
                                          :process-style="{
                                            'background-color': '#3e4452',
                                          }"
                                          ref="slider"
                                          v-model="value"
                                        >
                                          <template
                                            slot="tooltip"
                                            slot-scope="{ value }"
                                          >
                                            <div class="diy-tooltip">
                                              {{ formatter(value) }}
                                            </div>
                                          </template>
                                        </vue-range-slider>
                                        <div class="clearfix"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 p-0 mt-4 mb-4">
                                  <div
                                    class="
                                      display
                                      checkboxes
                                      one-in-row
                                      margin-bottom-10
                                      ch-1
                                      mr-2
                                    "
                                  >
                                    <div class="col pr-0 res-pl-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-bathroom-icon"
                                          style="
                                            position: absolute;
                                            left: 16%;
                                            z-index: 5;
                                            top: 14px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Bathroom-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          v-if="
                                            findIfActive('noOfBathrooms') == 1
                                          "
                                          @input="
                                            addToQuery(
                                              'bathrooms',
                                              _.get($event, 'value', '')
                                            )
                                          "
                                          :placeholder="'Bathrooms'"
                                          v-model="noOfBathrooms"
                                          :options="[
                                            { label: '1', value: '1' },
                                            { label: ' 2', value: '2' },
                                            { label: '3', value: '3' },
                                            {
                                              label: '4',
                                              value: '4',
                                            },
                                            { label: '5', value: '5' },
                                            { label: '6', value: '6' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pl-0 pr-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          style="
                                            position: absolute;
                                            left: 10%;
                                            z-index: 5;
                                            top: 14px;
                                            margin-right: 40px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Garage-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="findIfActive('garage') == 1"
                                          @input="addToQuery('garage', $event)"
                                          :placeholder="'Garage'"
                                          :multiple="true"
                                          v-model="garageOptions"
                                          :options="[
                                            {
                                              label: 'Single',
                                              value: 'single',
                                            },
                                            {
                                              label: 'Double',
                                              value: 'double',
                                            },
                                            { label: 'Other', value: 'other' },
                                            { label: 'None', value: 'none' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pl-0 pr-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-parking-icon"
                                          style="
                                            position: absolute;
                                            left: 11%;
                                            z-index: 5;
                                            top: 15px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Parking-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="findIfActive('parking') == 1"
                                          @input="addToQuery('parking', $event)"
                                          :placeholder="'Parking'"
                                          :multiple="true"
                                          v-model="parkingOptions"
                                          :options="[
                                            {
                                              label: 'Off Street',
                                              value: 'offStreet',
                                            },
                                            {
                                              label: 'On Street',
                                              value: 'onStreet',
                                            },
                                            {
                                              label: 'Allocated',
                                              value: 'allocated',
                                            },
                                            {
                                              label: 'Permitted',
                                              value: 'permitted',
                                            },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pr-0 res-pl-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-garden-icon"
                                          style="
                                            position: absolute;
                                            left: 17%;
                                            z-index: 5;
                                            top: 14px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Garden-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="
                                            findIfActive('noOfBathrooms') == 1
                                          "
                                          @input="
                                            addToQuery(
                                              'garden',
                                              _.get($event, 'value', '')
                                            )
                                          "
                                          :placeholder="'Garden'"
                                          v-model="gardenOptions"
                                          :options="[
                                            { label: 'Yes', value: 'yes' },
                                            { label: ' No', value: 'no' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                    <div class="col pl-0 pr-0">
                                      <div
                                        class="form-group bath remove-margin"
                                      >
                                        <span
                                          class="res-swmin-icon"
                                          style="
                                            position: absolute;
                                            left: 11.5%;
                                            z-index: 5;
                                            top: 16px;
                                          "
                                          ><img
                                            class="bedroomAdvance"
                                            :src="
                                              require('../assets/images/icons/Swimming-Pool-Icon.webp')
                                            "
                                        /></span>
                                        <multiselect
                                          :searchable="false"
                                          select-label=""
                                          deselectLabel=""
                                          selectedLabel=""
                                          v-if="
                                            findIfActive('swimmingPool') == 1
                                          "
                                          @input="
                                            addToQuery('swimmingPool', $event)
                                          "
                                          :placeholder="'Swimming Pool'"
                                          :multiple="true"
                                          v-model="swimmingPoolOptions"
                                          :options="[
                                            {
                                              label: 'Indoor',
                                              value: 'indoor',
                                            },
                                            {
                                              label: 'Outdoor',
                                              value: 'outdoor',
                                            },
                                            { label: 'None', value: 'none' },
                                          ]"
                                          :clear-on-select="false"
                                          :preserve-search="true"
                                          label="label"
                                          track-by="value"
                                        >
                                          <template
                                            slot="selection"
                                            slot-scope="{ values, isOpen }"
                                            ><span
                                              class="multiselect__single"
                                              v-if="values.length &amp;&amp; !isOpen"
                                              >{{ values.length }} option{{
                                                values.length > 1 ? "s" : ""
                                              }}
                                              selected</span
                                            ></template
                                          >
                                          <template #tag
                                            ><p class="d-none"></p>
                                          </template>
                                        </multiselect>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="tabs_2">
                        <div class="rld-main-search">
                          <div class="row">
                            <div class="rld-single-input">
                              <input
                                type="text"
                                placeholder="Enter postCode..."
                              />
                            </div>
                            <div class="rld-single-select ml-22">
                              <select class="select single-select">
                                <option value="1">Property Type</option>
                                <option value="2">Family House</option>
                                <option value="3">Apartment</option>
                                <option value="3">Condo</option>
                              </select>
                            </div>
                            <div class="rld-single-select">
                              <select class="select single-select mr-0">
                                <option value="1">Location</option>
                                <option value="2">Los Angeles</option>
                                <option value="3">Chicago</option>
                                <option value="3">Philadelphia</option>
                                <option value="3">San Francisco</option>
                                <option value="3">Miami</option>
                                <option value="3">Houston</option>
                              </select>
                            </div>
                            <div class="dropdown-filter">
                              <span>Advanced Search</span>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 pl-0">
                              <a
                                @click="search"
                                class="btn btn-yellow bsd"
                                href="javascript:void(0)"
                                >Search Now</a
                              >
                            </div>
                            <div
                              class="explore__form-checkbox-list full-filter"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-6 py-1 pr-30 pl-0">
                                  <div class="form-group categories">
                                    <div
                                      class="nice-select form-control wide"
                                      tabindex="0"
                                    >
                                      <span class="current"
                                        ><i class="fa fa-home"></i>Tenure
                                      </span>
                                      <ul class="list">
                                        <li
                                          data-value="1"
                                          class="option selected"
                                        >
                                          For Sale
                                        </li>
                                        <li data-value="2" class="option">
                                          For Rent
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 py-1 pr-30 pl-0">
                                  <div class="form-group beds">
                                    <div
                                      class="nice-select form-control wide"
                                      tabindex="0"
                                    >
                                      <span class="current"
                                        ><i
                                          class="fa fa-bed"
                                          aria-hidden="true"
                                        ></i>
                                        Bedrooms</span
                                      >
                                      <ul class="list">
                                        <li
                                          data-value="1"
                                          class="option selected"
                                        >
                                          1
                                        </li>
                                        <li data-value="2" class="option">2</li>
                                        <li data-value="3" class="option">3</li>
                                        <li data-value="3" class="option">4</li>
                                        <li data-value="3" class="option">5</li>
                                        <li data-value="3" class="option">6</li>
                                        <li data-value="3" class="option">7</li>
                                        <li data-value="3" class="option">8</li>
                                        <li data-value="3" class="option">9</li>
                                        <li data-value="3" class="option">
                                          10
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-md-6 py-1 pl-0 pr-0">
                                  <div class="form-group bath">
                                    <div
                                      class="nice-select form-control wide"
                                      tabindex="0"
                                    >
                                      <span class="current"
                                        ><i
                                          class="fa fa-bath"
                                          aria-hidden="true"
                                        ></i>
                                        Bathrooms</span
                                      >
                                      <ul class="list">
                                        <li
                                          data-value="1"
                                          class="option selected"
                                        >
                                          1
                                        </li>
                                        <li data-value="2" class="option">2</li>
                                        <li data-value="3" class="option">3</li>
                                        <li data-value="3" class="option">4</li>
                                        <li data-value="3" class="option">5</li>
                                        <li data-value="3" class="option">6</li>
                                        <li data-value="3" class="option">7</li>
                                        <li data-value="3" class="option">8</li>
                                        <li data-value="3" class="option">9</li>
                                        <li data-value="3" class="option">
                                          10
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="
                                    col-lg-5 col-md-12 col-sm-12
                                    py-1
                                    pr-30
                                    mr-5
                                    sld
                                  "
                                >
                                  <div class="main-search-field-2">
                                    <div class="range-slider">
                                      <label>Area Size</label>
                                      <div
                                        id="area-range-rent"
                                        data-min="0"
                                        data-max="1300"
                                        data-unit="sq ft"
                                      ></div>
                                      <div class="clearfix"></div>
                                    </div>
                                    <br />
                                    <div class="range-slider">
                                      <label>Price Range</label>
                                      <div
                                        id="price-range-rent"
                                        data-min="0"
                                        data-max="600000"
                                        data-unit="$"
                                      ></div>
                                      <div class="clearfix"></div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-lg-3 col-md-6 col-sm-12 py-1 pr-30"
                                >
                                  <div
                                    class="
                                      checkboxes
                                      one-in-row
                                      margin-bottom-10
                                      ch-1
                                    "
                                  >
                                    <input
                                      id="check-16"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-16"
                                      >Air Conditioning</label
                                    >
                                    <input
                                      id="check-17"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-17">Swimming Pool</label>
                                    <input
                                      id="check-18"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-18"
                                      >Central Heating</label
                                    >
                                    <input
                                      id="check-19"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-19">Laundry Room</label>
                                    <input
                                      id="check-20"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-20">Gym</label>
                                    <input
                                      id="check-21"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-21">Alarm</label>
                                    <input
                                      id="check-22"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-22"
                                      >Window Covering</label
                                    >
                                  </div>
                                </div>
                                <div
                                  class="col-lg-3 col-md-6 col-sm-12 py-1 pr-30"
                                >
                                  <div
                                    class="
                                      display
                                      checkboxes
                                      one-in-row
                                      margin-bottom-10
                                      ch-2
                                    "
                                  >
                                    <input
                                      id="check-23"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-23">WiFi</label>
                                    <input
                                      id="check-24"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-24">TV Cable</label>
                                    <input
                                      id="check-25"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-25">Dryer</label>
                                    <input
                                      id="check-26"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-26">Microwave</label>
                                    <input
                                      id="check-27"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-27">Washer</label>
                                    <input
                                      id="check-28"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-28">Refrigerator</label>
                                    <input
                                      id="check-29"
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label for="check-29">Outdoor Shower</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div data-v-2a183b29="" class="col-12 mb-3">
                  <div
                    data-v-2a183b29=""
                    class="banner-inner justify-content-center"
                  >
                    <div
                      style="margin-bottom: 70px"
                      data-v-2a183b29=""
                      class="pl-3 pr-3 res-plt"
                    >
                      <h3 style="color: white" class="mt-5 text-center">
                        <!-- Join 1000’s of smart sellers -->
                      </h3>

                      <div class="text-center">
                        <button
                          class="res-button-how res-butt"
                          @click="takeToHowItWorks"
                          style="
                            background-color: #548f4d;
                            color: white;
                            padding: 20px 89px;

                            border-radius: 8px;

                            border-color: rgb(84, 143, 77) !important;
                            border: 0px;
                            margin-right: 20px;
                            font-family: 'Montserrat', sans-serif;
                          "
                        >
                          How it Works
                        </button>
                        <button
                          class="res-button-prop"
                          @click="navigate('/add-property')"
                          style="
                            background-color: #548f4d;
                            color: white;
                            padding-top: 20px;
                            padding-left: 60px;
                            padding-bottom: 20px;
                            padding-right: 60px;
                            border-radius: 8px;

                            border-color: rgb(84, 143, 77) !important;
                            border: 0px;
                            font-family: 'Montserrat', sans-serif;
                          "
                        >
                          List your own property
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mt-4">
        <div class="container">
          <div v-if="sellerSaveShow()" class="row justify-content-md-center">
            <div class="col-md-6 pr-1 pt-1"></div>
          </div>
        </div>
      </section>
      <section
        v-lazyload
        :data-url="require('../assets/images/bg/home_section.webp')"
        class="mt-4 info-help h18 pedrem"
      >
        <div class="container">
          <div class="row justify-content-md-center info-head">
            <div class="col-lg-12 col-md-8 col-xs-8">
              <div class="info-text d-flex justify-content-center">
                <div class="pl-3 pr-3">
                  <video
                    class="res-video"
                    id="video"
                    :src="require('../assets/images/Vencasa-Short-Ad-4K.mp4')"
                    :poster="require('../assets/images/vencasa-snap.png')"
                    webkit-playsinline
                    loop
                    controls
                    playsinline
                  ></video>
                  <div class="play-button-wrapper">
                    <div
                      title="Play video"
                      class="play-gif"
                      id="circle-play-b"
                      style="opacity: 1"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="how-it-works"
        style="margin-top: 90px; margin-left: 25px; margin-right: 25px"
        _ngcontent-bgi-c3=""
        class="featured-boxes-area res-s-dnone"
      >
        <div id="how" class="title container mt-5">
          <h6
            class="text-center"
            style="font-family: 'Montserrat', sans-serif; font-weight: 600"
          >
            VENCASA EXPLAINED
          </h6>
          <h2
            class="text-center"
            style="font-family: 'Montserrat', sans-serif; font-weight: 600"
          >
            How it Works
          </h2>
        </div>

        <div style="margin: 2em auto; width: 80%" class="row">
          <ul
            style="margin-left: -60px; margin-right: -60px"
            class="progress-steps"
          >
            <li class="active">
              <strong
                @click="$router.push('/add-property')"
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5 pfont"
                >List your property</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Tell us about your property/ take pictures, write a description
                and create a floor plan.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5 pfont"
                >Make payment</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                One simple payment, no hidden fees or percentage commissions. We
                also plant a tree on your behalf.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5 pfont"
                >Show off your property</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Share your new listing with friends on social media. Message
                buyers directly and arrange viewings.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5 pfont"
                >Accept an Offer</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Accept an offer you are happy with and your solicitors will
                handle all the legal side of things.
              </p>
            </li>

            <li>
              <strong
                style="
                  text-transform: initial;
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 600;
                "
                class="mb-5 pfont"
                >Relax</strong
              >
              <p
                style="
                  text-transform: initial;
                  margin-left: 2px;
                  margin-right: 4px;
                  padding-right: 10px;
                  font-size: 1rem;
                  padding-left: 10px;
                  font-family: Montserrat, sans-serif;
                "
                class="mt-4"
              >
                Congratulations, now sit back and ponder the ways to treat
                yourself with your saved commission.
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section
        class="blog-section bg-white"
        :style="`background-image : url(${require('@/assets/images/bg/big.webp')}) !important; background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
    padding: 0px;
    background-repeat: no-repeat !important;`"
      >
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-auto mt-5">
              <h6
                class="text-uppercase text-center mb-4"
                style="font-family: 'Montserrat', sans-serif; font-weight: 600"
              >
                Our Pricing
              </h6>
              <h3
                class="text-center mb-5"
                style="font-size: 30px; font-family: 'Montserrat', sans-serif"
              >
                One Plan, Fixed Pricing
              </h3>
              <div
                class="text-center"
                style="
                  border: 3px solid;
                  margin-bottom: 20px;
                  border-color: rgb(84, 143, 77);
                  border-radius: 8px;
                "
              >
                <div class="p-3">
                  <h5
                    class="mb-3"
                    style="
                      font-weight: 700;
                      font-size: 25px;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    The One
                  </h5>
                  <h5
                    class="mb-3"
                    style="
                      font-size: 45px;
                      color: #548f4d;
                      font-weight: 700;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    £250
                  </h5>
                  <h6
                    class="mb-5"
                    style="
                      font-weight: bold;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    WHAT'S INCLUDED
                  </h6>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Direct Contact with Potential Buyer
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    List Until Sold
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Offers Sent Instantly
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Unlimited viewings at Times to Suit You
                  </p>
                  <p
                    class="list-des m30"
                    style="
                      margin-bottom: 30px;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    You Keep Full Control
                  </p>
                  <button
                    @click="$router.push('/add-property')"
                    class="res-button-how"
                    style="
                      background-color: rgb(84, 143, 77);
                      color: white;
                      padding: 20px 60px;
                      border-radius: 8px;
                      border: 0px;

                      margin-bottom: 10px;
                      font-family: 'Montserrat', sans-serif;
                    "
                  >
                    List Your Property
                  </button>
                </div>
              </div>
              <div class="text-center">
                <span style="font-family: 'Montserrat', sans-serif">
                  Are you a property developer?
                </span>
                <router-link
                  style="font-family: 'Montserrat', sans-serif"
                  to="/contact-us"
                >
                  Contact us
                </router-link>
              </div>
              <br />
            </div>
          </div>
          <div class="sec-title">
            <h2 class="res-mt-2">A Community of smart buyers and sellers</h2>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-6">
              <div
                class="res-margin-save-set"
                style="margin-top: 25%; margin-bottom: 25%"
              >
                <h2>Save Money, No Estate Agent's Fees</h2>
                <p
                  style="
                    line-height: 30px;
                    font-family: 'Montserrat', sans-serif;
                  "
                >
                  Estate agent’s fees add thousands of pounds to the cost of
                  selling a home. Estate agents will usually charge a percentage
                  fee, which can range from 1% to 5% of the agreed selling
                  price.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <img
                v-lazyload
                class="img-fluid"
                :data-url="require('../assets/images/bg/image1.webp')"
              />
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-md-6 res-big-display">
              <div style="margin-top: 15%; margin-bottom: 15%">
                <h2>Connecting Buyers & Sellers Directly</h2>
                <p
                  style="
                    line-height: 30px;
                    font-family: 'Montserrat', sans-serif;
                  "
                >
                  SELLING with Vencasa means you keep full control and speak
                  with buyers directly. You can arrange your own viewings and
                  negotiate on price, all within Vencasa’s safe messaging
                  service.
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <img
                v-lazyload
                class="img-fluid"
                :data-url="require('../assets/images/bg/image2.webp')"
              />
            </div>
            <div class="col-md-6 res-display">
              <div style="margin-top: 25%; margin-bottom: 25%">
                <h2>
                  Connecting Buyers & Sellers <br />
                  Directly
                </h2>
                <p style="line-height: 30px">
                  SELLING with Vencasa means you keep full control and speak
                  with buyers directly. You can arrange your own viewings and
                  negotiate on price, all within Vencasa’s safe messaging
                  service.
                </p>
              </div>
            </div>
          </div>
          <div class="sec-title">
            <h2 class="mt-2"><span>Articles &amp; </span>Tips</h2>
            <p style="font-family: 'Montserrat', sans-serif">
              Read the latest news from our blog.
            </p>
          </div>
          <div class="news-wrap mb-5">
            <div class="row">
              <articles
                class="hover"
                v-for="blog in blogs ? blogs.slice(0, 3) : []"
                :key="blog.id"
                :blog="blog"
              />
            </div>
          </div>
        </div>
      </section>
      <a data-scroll href="#wrapper" class="go-up" style="display: inline"
        ><i class="fa fa-angle-double-up" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
const articles = () =>
  import(/* webpackChunkName: "pyioifsda9" */ "../components/articles");
import "vue-range-component-fixed/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component-fixed";
import { formatter } from "../utils";
import Multiselect from "vue-multiselect";
import $ from "jquery";

export default {
  data() {
    return {
      gardenOptions: [],
      noOfBathrooms: [],
      noOfBedrooms: [],
      radiusOptions: [],
      swimmingPoolOptions: [],
      parkingOptions: [],
      garageOptions: [],
      types: [],
      canBeMultiple: ["type", "garage", "parking", "swimmingPool"],
      searchOpen: false,
      rangeValues: [
        0, 50000, 60000, 70000, 80000, 90000, 100000, 120000, 140000, 160000,
        180000, 200000, 220000, 240000, 260000, 280000, 300000, 320000, 340000,
        360000, 380000, 400000, 420000, 440000, 460000, 480000, 500000, 600000,
        700000, 800000, 900000, 1000000, 1250000, 1500000, 1750000, 2000000,
        2250000, 2500000, 2750000, 3000000, 3250000, 3500000, 3750000, 4000000,
        4250000, 4500000, 4750000, 5000000, 6000000, 7000000, 8000000, 9000000,
        10000000,
      ],
      query: {},
      value: [0, 0],
      responsive: {
        0: {
          items: 1,
          center: false,
        },
        480: {
          items: 1,
          center: false,
        },
        520: {
          items: 2,
          center: false,
        },
        600: {
          items: 2,
          center: false,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1366: {
          items: 4,
        },
        1400: {
          items: 4,
        },
      },
    };
  },
  computed: {
    _() {
      return _;
    },
    sellerSavePercentage() {
      let matched = this.configurations.find((configuration) => {
        return configuration.key == "sellerSavePercentageAmount";
      });
      return matched ? formatter(matched.val).toString() : "";
    },
    ...mapGetters({
      blogs: "getBlogs",
      authUser: "auth",
      configurations: "getConfigurations",
      check: "check",
      myBuying: "getMyBuying",
      userProperties: "getUserProperties",
    }),
  },
  components: {
    articles,
    VueRangeSlider,
    Multiselect,
  },
  methods: {
    formatter: formatter,
    navigate(path) {
      this.$router.push(path);
    },
    takeToHowItWorks() {
      document
        .querySelector("#how-it-works")
        .scrollIntoView({ behavior: "smooth" });
    },
    checkForChange() {
      this.isAdvancedSearchOpen();
    },
    isAdvancedSearchOpen() {
      this.searchOpen = !document
        .querySelector(".full-filter")
        ?.classList.contains("filter-block");
    },
    addToQuery(param, event) {
      console.log({ param, event });
      if (typeof event == "object") {
        if (param == "price") {
          let obj = { priceFrom: "", priceTo: "" };
          for (let i = 0; i < Object.keys(obj).length; i++) {
            this.query[Object.keys(obj)[i]] = event[i];
          }
        } else if (this.canBeMultiple.includes(param)) {
          let queryString = "";
          for (let i = 0; i < event.length; i++) {
            queryString =
              queryString + `${event[i].value}${event[i + 1] ? "," : ""}`;
          }
          this.query[param] = queryString;
        }
        return;
      }
      this.query[param] = event;
    },
    search() {
      this.query["perPage"] = "10";
      this.$router.push({ path: "listing", query: this.query });
    },
    initialized() {
      console.log("initialized");
    },
    sellerSaveShow() {
      let matchedConfig = this.configurations.find((configuration) => {
        return configuration.key == "sellerSavePercentageShow";
      });
      return _.get(matchedConfig, "val", false);
    },
    findIfActive(config) {
      if (!_.isEmpty(this.configurations)) {
        let matchedConfig = this.configurations.find((configuration) => {
          return configuration.key == config;
        });
        return matchedConfig ? matchedConfig.val.isActive : 0;
      } else return 0;
    },
    resize() {
      if ($(window).width() < 700) {
        $(".checkboxes").removeClass("display");
      } else if ($(window).width() > 700) {
        $(".checkboxes").addClass("display");
      }
    },
  },
  destroyed() {
    document.querySelector("body").removeEventListener("click", (e) => {
      if (
        !e.target?.classList.contains("advanceSlow") &&
        document
          .querySelector(".full-filter")
          .classList.contains("filter-block")
      ) {
        e.stopPropagation();
        document.querySelector(".advanceSlow").click();
      }
    });
  },
  async mounted() {
    this.resize();
    this.$nextTick(() => {
      $(".dropdown-filter").on("click", function () {
        $(".explore__form-checkbox-list").toggleClass("filter-block");
      });
      document.querySelector("body").addEventListener("click", (e) => {
        if (e.target) {
          let found = e.path.find((p) => {
            return p.classList ? p.classList?.contains("full-filter") : false;
          });
          if (
            !e.target?.classList.contains("advanceSlow") &&
            (document.querySelector(".full-filter")
              ? document
                  .querySelector(".full-filter")
                  .classList.contains("filter-block")
              : false) &&
            !found
          ) {
            e.stopPropagation();
            document.querySelector(".advanceSlow").click();
          }
        }
      });
      const video = document.getElementById("video");
      const circlePlayButton = document.getElementById("circle-play-b");

      function togglePlay() {
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }

      circlePlayButton.addEventListener("click", togglePlay);
      video.addEventListener("playing", function () {
        circlePlayButton.style.opacity = 0;
      });
      video.addEventListener("pause", function () {
        circlePlayButton.style.opacity = 1;
      });
    });
    if (_.isEmpty(this.authUser)) {
      await this.$store.dispatch("getUser");
    }
    if (_.isEmpty(this.configurations)) {
      await this.$store.dispatch("getConfigurations");
    }
    if (!this.myBuying.length && this.check)
      await this.$store.dispatch("getMyBuying");
    if (!this.userProperties.length && this.check)
      await this.$store.dispatch("getUserProperties");
    if (!this.blogs) {
      this.$store.dispatch("blogs", { page: 1, perPage: 10 });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/search-form.css"></style>
<style scoped src="../assets/css/search.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/colors/black.css"></style>

<style scoped>
div >>> .multiselect__option--selected {
  background: #548f4d;
  color: white;
  font-weight: 500;
}

div >>> .multiselect__option--selected.multiselect__option--highlight {
  background: #548f4d;
  color: white;
  font-weight: 500;
}

div >>> .multiselect__option--highlight {
  background: white;
  color: #4f5f73;
}

.w-124 {
  width: 225px !important;
}

.margin-rightt {
  margin-right: 18px !important;
}

.w1001 {
  width: 100% !important;
}

div >>> .multiselect__select {
  right: 19px !important;
}

div >>> .multiselect__tags {
  padding-left: 20px !important;
  margin-left: 0px !important;
  margin-right: 18px !important;
  width: 100% !important;
}

@media (min-width: 1200px) {
  .res-big-display {
    display: none;
  }
}

@media (min-width: 760px) and (max-width: 1900px) {
  .pfont {
    font-size: 16px !important;
  }
}

@media (min-width: 1901px) and (max-width: 3000px) {
  .pfont {
    font-size: 25px !important;
  }
}

@media (max-width: 700px) {
  .res-butt {
    display: none !important;
  }

  .w-124 {
    width: 100% !important;
  }

  .resp-margin-topp {
    margin-top: 7px !important;
  }

  .res-styles {
    letter-spacing: -0.4px !important;
  }

  .res-margin-seting {
    margin-bottom: 6% !important;
  }

  .w1001 {
    width: auto !important;
  }
  .res-pl-0 {
    padding-left: 0px !important;
  }

  .margin-rightt {
    margin-right: 0px !important;
  }

  .res-video {
    height: 200px !important;
    width: 100% !important;
  }

  .rld-single-input {
    width: 100% !important;
  }

  div >>> .multiselect {
    width: 100% !important;
    padding-left: 0px !important;
    margin: 0 !important;
  }
  .homepage-5 .rld-main-search {
    height: 250px !important;
  }

  .res-marginr-20 {
    margin-right: 20px !important;
  }

  .res-display-unset {
    display: unset !important;
  }
  .res-plr-0 {
    padding-right: 0px !important;
  }
  .res-p {
    padding-top: 187px;
  }
  .res-swinign {
    margin-left: 14px !important;
    width: 94% !important;
  }

  .res-s-dnone {
    display: none;
  }
  .res-bimg {
    background-size: 100% 268px !important;
    height: 795px !important;
  }

  .res_remove_ml {
    margin-left: 0px !important;
  }
  .res-width {
    width: 100% !important;
  }
  .float-rights {
    text-align: center !important;
    float: none !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 265px !important;
  }
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  div >>> .height0 {
    height: 360px !important;
  }
  .wide {
    width: 100% !important;
  }
  .res-plt {
    margin-top: 70px !important;
  }
  .res-button-how {
    margin: 0px !important;
    width: 250px !important;
    padding: 13px !important;
  }

  .res-button-api {
    width: 320px !important;
    padding: 12px !important;
    margin: 13px !important;
  }

  div >>> .multiselect__tags {
    padding-right: 0px;

    width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 10px !important;

    padding-left: 20px !important;
    margin-left: 0px !important;
    margin-right: 18px !important;
  }

  div >>> .multiselect__select {
    left: 83% !important;
  }

  .res-search-button-new {
    padding: 0px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .res-mrl-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  div >>> .multiselect {
    padding-left: 0px !important;
  }

  .res-postal-code {
    margin-right: 0px !important;
    /* width: 289px !important;
    margin-left: 24px !important;
    padding-left: 32px !important; */
  }
  .res-threedot {
    margin-left: 19px !important;
    width: 89.9% !important;
  }

  .res-margin-save-set {
    margin-top: 15% !important;
  }

  .sec-title {
    padding-bottom: 0px !important;
  }

  .res-mt-2 {
    margin-top: 40px !important;
  }

  .admin {
    display: none !important;
  }

  .res-threedot span {
    padding-right: 24px !important;
  }

  .res-bed-icon {
    position: absolute;
    left: 12.5% !important;
    z-index: 9999;
    top: 112px;
    top: 66% !important;
  }

  .res-bathroom-icon {
    left: 10% !important;
  }

  .res-garden-icon {
    left: 10% !important;
  }

  .res-parking-icon {
    left: 10% !important;
  }

  .res-swmin-icon {
    left: 10% !important;
  }

  .res-pl-0 {
    padding-left: 0px !important;
  }

  .res-button-prop {
    padding: 15px 35px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .property-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .dropdown-filter span:after {
    margin-left: 198px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;

    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 600px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    width: 95% !important;
    padding-right: 24px !important;
    margin: 0;
    padding-left: 78% !important;
  }
  .res-display {
    display: none;
  }
}

@media (min-width: 700px) and (max-width: 786px) {
  .homepage-5 .parallax-searchs.home17 {
    height: 394px !important;
  }
  .homepage-5 .info-help.h18 {
    padding-top: 63px !important;
    padding-bottom: 63px !important;
  }
  .res-s-dnone {
    display: none;
  }
  .res-search-1 {
    margin-left: 6px !important;
    width: 95% !important;
  }
  .res-dots {
    margin-left: 7px !important;
    width: 95% !important;
    padding-left: 5% !important;
    padding-right: 85% !important;
  }
  .res-display {
    display: none;
  }
}

div >>> .vs--searchable .vs__dropdown-toggle {
  cursor: text;
  width: 200px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 0px 8px rgb(255 255 255 / 20%);
  height: 48px;
  padding: 0 34px 0 34px;
  line-height: 47px;
  font-weight: 400;
  color: #000;
  font-family: "Lato", sans-serif;
  background: white;
  word-wrap: normal;
  margin-right: 0px !important;
  margin-left: 15px;
}

.setwidth-all {
  width: 200px !important;
}

.display {
  display: flex !important;
}
.property-margin {
  margin-right: 0px !important;
  margin-left: 15px;
}
.remove-margin {
  margin-bottom: 0px !important;
}

.btn-yellow:hover {
  color: #272b32 !important;
}
.bsd:hover {
  border: 1px solid #548f4d !important;
}

div >>> .slider-process {
  max-width: 1014px;
}

div >>> .slider {
  max-width: 1010px;
}

.float-rights {
  float: right;
}

div >>> .slider-dot .slider-always {
  transform: translateX(998px) !important;
}
.mar-settg {
  margin-right: -7px;
  margin-left: -7px;
}

.fieldBed {
  margin-right: 0px !important;
  margin-left: 15px !important;
  height: 48px !important;
  line-height: 47px !important;
  width: 100%;
  padding: 12px 56px 12px 20px !important;
  border: 1px solid #ebebeb !important;
  transition: all 0.4s ease !important;
  position: relative !important;
  border-radius: 8px !important;
}
.w-196 {
  width: 196px !important;
}
.progress-container {
  width: 80%;
  margin: 2em auto;
}
.progress-steps {
  counter-reset: step;
}
.progress-steps li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
}
.progress-steps li:before {
  width: 4.5em;
  height: 4.5em;
  content: counter(step);
  counter-increment: step;
  line-height: 4em;
  border: 6px solid green;
  display: block;
  text-align: center;
  margin: 10px auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  padding-top: 2px;
}
.progress-steps li:after {
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  background-color: green;
  top: 2.6em;
  left: -50%;
  z-index: -1;
}
.progress-steps li:first-child:after {
  content: none;
}
.list-des {
  font-size: 17px;
  font-weight: 600;
}
.bedroomAdvance {
  color: rgb(97, 140, 87);
  font-size: 25px;
  height: 22px;
  width: 22px;
  padding-right: 2px;
  margin-top: -7px;
}
.pedrem {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.m30 {
  margin-bottom: 30px;
}
.res-llist {
  width: 100%;
  padding-left: 8px !important;
}

div >>> .homepage-5 .btn-yellow:hover {
  background: #548f4d !important;
  background-color: #548f4d !important;
}

div >>> .multiselect__placeholder {
  padding: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__input {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}
div >>> .multiselect__tags {
  margin-left: 15px;
  height: 50px;
  max-width: 202px;
  width: 202px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
  padding-top: 13px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 0 8px rgb(255 255 255 / 20%);
  padding-left: 28px;
}

div >>> .multiselect__select:before {
  top: 75%;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: black;
  font-size: inherit;
}

div >>> .multiselect__placeholder {
  padding-left: 4px;
}
</style>
